<template>
  <header
      :class="{
      'page-header': true,
      'l-first': level === 1,
    }"
      :data-level="level"
  >
    <div class="page-header-top">
      <div class="page-header-main">
        <div class="page-header-logos" :class="{'side-by-side' : sideBySideLogos}">
          <img v-if="logoUrl && !hiddenLogo" ref="logoRef" :src="logoUrl" class="page-header-logo" :alt="$t('Wcag.CompanyLogoName', { company: companyLogoName})" @error="hiddenLogo=true"/>
          <img v-if="logoMsp" ref="logoMsp" :src="logoMsp" class="page-header-logo" :alt="$t('Wcag.CompanyLogoName', { company: MSPLogoName})" />
        </div>
        <div class="pb-4">
          <div>
            <Link v-if="backTo" :to="backTo" class="page-header-back" variant="unstyled"> <!--TODO maybe route.back() or .go(-1)? -->
              <Icon name="chevronRight"/>
              {{ $t('General.Back') }}
            </Link>
            <Txt as="h1" :type="level === 1 ? 'h2' : 'h3'" class="page-header-title" no-margin>
              {{ title }}
            </Txt>
            <Txt v-if="description" class="page-header-description" small>{{ description }}</Txt>
          </div>
        </div>

        <div v-if="$slots.banner">
          <slot name="banner"/>
        </div>
      </div>
      <div v-if="$slots.right" class="page-header-right">
        <slot name="right"/>
      </div>
    </div>
    <div v-if="$slots.bottom" class="page-header-bottom">
      <slot name="bottom"/>
    </div>
  </header>
</template>

<script>
import Txt from '@/components/atoms/Txt.vue';
import Link from '@/components/atoms/Link.vue';
import Icon from '@/components/atoms/Icon.vue';
import CompanyService from "@/services/company.service";
import { CyberGuru } from "@/common/constants";

export default {
  components: {Txt, Link, Icon},
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: undefined,
    },
    backTo: {
      type: String,
      default: undefined,
    },
    level: {
      type: Number,
      default: 1,
    },
    sideBySideLogos: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      hiddenLogo: false,
      logoUrl: null,
      logoMsp: null,
      companyLogoName: null,
      MSPLogoName: null,
    };
  },
  mounted() {
    this.logoUrl = this.$company.logo;
    if (this.$company.created_by_company.uuid) {
      if (this.$company.company_options.display_msp_logo) {
        CompanyService.getParentCompanyLogo(this.$user.company_id)
            .then((res) => {
              this.logoMsp = res.data.logo || res.data.toggle_logo
            });
      }
    }
    this.companyLogoName = this.$company.displayname;
    this.MSPLogoName = this.$company.created_by_company.displayname || CyberGuru;
  }
};
</script>

<style lang="scss" scoped>
.page-header {
  @apply mb-4;
}

.page-header-top {
  @apply flex flex-wrap items-start justify-between;
}

.page-header-main {
  @apply flex-grow;
  @apply basis-full sm:basis-auto;
  .page-header-logos {
    @apply flex justify-between;
  }

  .page-header-logos.side-by-side {
    @apply lg:justify-start lg:gap-5
  }
}

.page-header-right {
  @apply shrink-0;
  @apply basis-full sm:basis-auto;
  @apply mt-8 sm:mt-0;
}

.page-header-logo {
  @apply max-w-full h-10;
  @apply mb-10;
}

.page-header-back {
  @apply inline-flex items-center;
  @apply text-sm font-bold text-violet-700;
  @apply mb-4;

  .icon {
    @apply text-accent-500 text-2xl;
    @apply rotate-180;
    @apply -translate-y-px;
    // font adjustment
    @apply -translate-x-1.5;
    // icon adjustment
  }
}

.page-header-title {
}

.page-header-description {
  @apply mt-2.5;
}

.page-header-bottom {
  @apply mt-8 lg:mt-10;
}
</style>

<template>
  <div v-if='display' class='white-overlay'>
  <transition appear name='fade'>
      <div class='support-wrapper'>
        <div class='support'>
          <div>
            <span><em>{{ $t('Support.Copyright', {currentYear: $dateTime.now().toFormat('yyyy')}) }}</em></span>
            <span>{{ $t('Support.Address') }}</span>
            <span>{{ $t('Support.Mail') }} <a :href="mailTo">{{ placeholderMailTo }}</a></span>
          </div>
          <div>
            <Button variant="outline" @click='show(false)'>
              {{ $t('General.Close') }}
            </Button>
          </div>
        </div>
      </div>
  </transition>
  </div>
</template>

<script>
import {SupportEmail} from "@/common/constants";

export default {
  data() {
    return {
      display: false
    };
  },
  computed:{
    mailTo()
    {
      return 'mailto:'+this.getCompanySupportMailInfo()
    },
    placeholderMailTo(){
      return this.getCompanySupportMailInfo()
    }
  },
  methods: {
    show(value) {
      this.display = value;
    },
    getCompanySupportMailInfo(){
      return this.$company.company_options?.support_address?.value || SupportEmail
    }
  }
};
</script>

<style lang='scss' scoped>
.white-overlay {
  background-color: rgba(150, 150, 150, 0.7);
  @apply absolute w-full h-full z-[9999];
}

.fade-enter-active,
.fade-leave-active {
  transition: all .5s;
}

.fade-enter, .fade-leave-to {
  @apply opacity-0;
  transform: translateY(200px);
}

.fade-enter-to, .fade-leave {
  @apply opacity-100;
  transform: translateY(0);
}

.support-wrapper {
  @apply absolute m-auto w-full bottom-0;
}

.support {
  @apply flex items-center justify-between w-full h-full py-4 px-10 flex-col md:flex-row space-y-7 md:space-y-0;
  @apply bg-white text-black text-xs;
  @apply shadow-2xl;
  & div:first-child {
    @apply flex flex-col space-y-3 items-center text-center md:text-left md:items-start md:space-y-1;
  }
  & a {
    @apply underline font-bold
  }
}
</style>
<template>
  <div class="pie-chart-wrapper">
    <ChartJSPie
      ref="chart"
      :class="{
        'pointer-events-none': noInteractions,
      }"
      :title="title"
      :chart-data="chartData"
      :dataset-id-key="datasetIdKey"
      :chart-options="config"
      :styles="{ width: '100%', height: '100%' }"
      :width="0"
      :height="0"
      tabindex="0"
      :aria-label="title"
      role="graphics-object"
      :aria-roledescription="$t('Wcag.PieChart')"
    />
    <div
      v-if="$slots.default"
      class="pie-chart-content"
      :style="{
        left: chartBounds ? `${chartBounds.left}px` : undefined,
        top: chartBounds ? `${chartBounds.top}px` : undefined,
        right: chartBounds ? `${chartBounds.right}px` : undefined,
        bottom: chartBounds ? `${chartBounds.bottom}px` : undefined,
        width: chartBounds ? `${chartBounds.width}px` : undefined,
        height: chartBounds ? `${chartBounds.height}px` : undefined,
      }"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import { Pie as ChartJSPie } from 'vue-chartjs/legacy'
import { mergeObjects } from '@/utils/object'
import '@/utils/charts'

export default {
  components: { ChartJSPie },
  props: {
    labels: {
      type: Array,
      required: false,
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    chartOptions: {
      type: Object,
      default: undefined,
    },
    chartData: {
      type: Object,
      default() {
        return {
          labels:   [],
          datasets: []
        }
      }
    },
    title: {
      type: String,
      default: undefined,
    },
    showLegend: {
      type: Boolean,
      default: false,
    },
    dashedLines: {
      type: Boolean,
      default: false,
    },
    noInteractions: {
      type: Boolean,
      default: false,
    },
    aspectRatio: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      renderComponent: false,
      chartBounds: {}
    }
  },
  computed: {
    config() {
      const defaultConfig = {
        responsive: true,
        aspectRatio: this.aspectRatio,
        maintainAspectRatio: !!this.aspectRatio,
        events: ['mousemove', 'mouseout'],
        plugins: {
          title: {
            display: !!this.title,
            text: this.title,
            padding: {
              bottom: 20
            }
          },
          legend: {
            display: this.showLegend,
          },
        },
      }
      return mergeObjects(defaultConfig, this.chartOptions || {})
    },
  },
  watch: {
    $props: {
      handler() {
        // force chart refresh
        this.$refs.chart.renderChart(this.chartData, this.config)
        this.updateChartBounds();
      },
      deep: true,
    },
  },
  methods: {
    updateChartBounds() {
      const chartArea = this.$refs.chart.$data._chart.chartArea

      this.chartBounds = { ...chartArea }
    }
  }
}
</script>

<style lang="scss" scoped>
.pie-chart-wrapper {
  @apply relative w-full h-full z-10;
}

.pie-chart-content {
  @apply absolute inset-0;
  @apply flex items-center justify-center;
}
</style>
